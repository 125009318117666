import {ShareTicketAction, ShareTicketState, ShareTicketActionTypes} from "../../types/History/shareTicketTypes";

const initialState: ShareTicketState = {
    shareTicketData: {},
    loading: false,
    shareTicketErrorDara: null,
};

export const shareTicketReducer = (state = initialState, action: ShareTicketAction): ShareTicketState => {
    switch (action.type) {
        case ShareTicketActionTypes.FETCH_SHARETICKET_DATA:
            return { ...state, loading: true, shareTicketErrorDara: null, shareTicketData: {} };
        case ShareTicketActionTypes.FETCH_SHARETICKET_DATA_SUCCESS:
            return { ...state, loading: false, shareTicketErrorDara: null, shareTicketData: action.payload };
        case ShareTicketActionTypes.FETCH_SHARETICKET_DATA_ERROR:
            return { ...state, loading: false, shareTicketErrorDara: action.payload, shareTicketData: {} };
        default:
            return state;
    }
};

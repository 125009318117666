import * as ConfirmOTPActionCreators from './OTP/confirmOTP';
import * as InitOperatorActionCreators from './Operator/operator';
import * as GetHistoryActionCreators from './History/history';
import * as GetShareTicketActionCreators from './History/share';
import * as InitPlayerActionCreators from './Player/player';
import * as SmartJackpotActionCreators from './SmartBet/smartBet';
import * as SmartJackpotsActionCreators from './SmartBet/smartBets';
import * as trackingSmartJackpotsActionCreators from './SmartBet/smartbetTracking';
import * as GenerateSmartJackpotActionCreators from './SmartBet/generateSmartBet';
import * as quickbetActionCreators from './Quickbet/quickbet';
import * as quickbetsActionCreators from './Quickbet/quickbets';
import * as reserveQuickbetsActionCreators from './Quickbet/quickbetReserve';
import * as createQuickbetsActionCreators from './Quickbet/quickbetCreate';
import * as quicktipActionCreators from './Quicktip/quicktip';
import * as quicktipsActionCreators from './Quicktip/quicktips';
import * as createQuicktipActionCreators from './Quicktip/quicktipCreate';
import * as reserveQuicktipActionCreators from './Quicktip/quicktipReserve';
import * as trackingQuicktipActionCreators from './Quicktip/quicktipTracking';
import * as superbetsActionCreators from './Superbet/superbets';
import * as superbetActionCreators from './Superbet/superbet';
import * as superbetTrackingActionCreators from './Superbet/superbetTracking';
import * as calculationTicketsValueActionCreators from './Calculation/calculation';
import * as jackpotActionCreators from './Jackpot/jackpot';
import * as jackpotsActionCreators from './Jackpot/jackpots';
import * as createJackpotActionCreators from './Jackpot/jackpotCreate';
import * as reserveJackpotActionCreators from './Jackpot/jackpotReserve';
import * as trackingJackpotActionCreators from './Jackpot/jackpotsTracking';
import * as pickBetsJackpotActionCreators from './Jackpot/jackpotPickBets';




export default {
    ...ConfirmOTPActionCreators,
    ...SmartJackpotActionCreators,
    ...SmartJackpotsActionCreators,
    ...GenerateSmartJackpotActionCreators,
    ...trackingSmartJackpotsActionCreators,
    ...quicktipActionCreators,
    ...quicktipsActionCreators,
    ...createQuicktipActionCreators,
    ...reserveQuicktipActionCreators,
    ...trackingQuicktipActionCreators,
    ...InitOperatorActionCreators,
    ...InitPlayerActionCreators,
    ...superbetsActionCreators,
    ...superbetActionCreators,
    ...superbetTrackingActionCreators,
    ...quickbetActionCreators,
    ...quickbetsActionCreators,
    ...GetHistoryActionCreators,
    ...GetShareTicketActionCreators,
    ...calculationTicketsValueActionCreators,
    ...jackpotActionCreators,
    ...jackpotsActionCreators,
    ...createJackpotActionCreators,
    ...reserveJackpotActionCreators,
    ...trackingJackpotActionCreators,
    ...reserveQuickbetsActionCreators,
    ...createQuickbetsActionCreators,
    ...pickBetsJackpotActionCreators

};
import { Dispatch } from "redux";
import {ShareTicketAction, ShareTicketActionTypes} from "../../types/History/shareTicketTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
    method?: string
}

interface ShareTicketInterface {
    code: string;
    data: {
        method: string;
        msisdn: string;
        email: string;
    };
}

export const handleShareTicketData = (params: ShareTicketInterface) => {
    let methodTypeValue = params.data.method;
    return async (dispatch: Dispatch<ShareTicketAction>) => {
        try {
            dispatch({ type: ShareTicketActionTypes.FETCH_SHARETICKET_DATA });
            const response = await axios({
                method: 'post',
                url: `/api/tickets/${params.code}/share`,
                data: params.data,
                withCredentials: false
            });

            if (response.data) {
                dispatch({
                    type: ShareTicketActionTypes.FETCH_SHARETICKET_DATA_SUCCESS,
                    payload: response.data
                });
            } if (response.data === null) {
                dispatch({
                    type: ShareTicketActionTypes.FETCH_SHARETICKET_DATA_ERROR,
                    payload: { code: response.status, message: 'Saved', method: methodTypeValue }
                });
            } else {
                dispatch({
                    type: ShareTicketActionTypes.FETCH_SHARETICKET_DATA_ERROR,
                    payload: { code: response.status, message: 'No data received' }
                });
            }
        } catch (error: any) {
            let errorPayload: ErrorResponse;

            if (error.response && error.response.data) {
                errorPayload = {
                    code: error.response.status || 500,
                    message: typeof error.response.data === 'string' ? error.response.data : error.response.data.message
                };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }

            dispatch({
                type: ShareTicketActionTypes.FETCH_SHARETICKET_DATA_ERROR,
                payload: errorPayload
            });
        }
    };
};
import React, {FC} from "react";
import QuicktipLobby from "./QuicktipLobby";
import SmartbetLobby from "./SmartbetLobby";
import SuperbetLobby from "./SuperbetLobby";
import QuickbetLobby from "./QuickbetLobby";
import JackpotLobby from "./JackpotLobby";
import {useLocation} from "react-router-dom";
import './Lobby.css'
const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;
const Lobby: FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode')
    const types = params.get('type')
        ?.split(',')
        .map((type) => parseInt(type, 10))
        .filter((type) => !isNaN(type)) || [];

    const authData = params.get('token');
    const atagVal = params.get('atag');

    if (authData) {
        localStorage.setItem('token', authData);
    } else {
        localStorage.removeItem('token');
    }

    const shouldRenderAll = types.length === 0;

    return (
        <div className="Lobby-container lite">
            <main
                className={mode === 'iframe' ? '' : "pt-2 pb-3"}
                style={{
                    backgroundImage: mode !== 'iframe' ? `url(${toAbsoluteUrl('/assets/img/bg/lobby-bg.webp')})` : 'none',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',
                }}
            >
                <div className={mode === 'iframe' ? 'container-fluid' : 'container'}>
                    <div className="row">
                        {(shouldRenderAll || types.includes(1)) && <QuicktipLobby atagParams={atagVal} />}
                        {(shouldRenderAll || types.includes(2)) && <SmartbetLobby mode={mode} atagParams={atagVal} />}
                        {(shouldRenderAll || types.includes(3)) && <SuperbetLobby atagParams={atagVal} />}
                        {(shouldRenderAll || types.includes(4)) && <QuickbetLobby atagParams={atagVal} />}
                        {(shouldRenderAll || types.includes(5)) && <JackpotLobby atagParams={atagVal} />}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Lobby;
